import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { ProcessorQueryType } from '+types';

import ProccessorQueryResultContainer from './processorQueryWidgets/ProcessorQueryResultContainer';
import ProcessorQuerySelectors from './processorQueryWidgets/ProcessorQuerySelectors';

import './index.scss';

const api = new APIRequest();

const ProcessorQuery = () => {
  const { feedbackInit } = useFeedbackHandler();
  const [processor, setProcessor] = useState('');
  const [ids, setIds] = useState<string[]>([]);
  const [views, setViews] = useState<{ [key: string]: boolean }>({});
  const [queries, setQueries] = useState<ProcessorQueryType[]>([]);

  const { isLoading, mutateAsync } = useMutation<ProcessorQueryType[], unknown>(
    () => api.queryVbaProcessorTransactions({ references: ids, processor }),
    {
      onMutate: () => {
        setQueries([]);
        setViews({});
      },
      onSuccess: data => {
        setQueries(data);
      },
      onError: e => {
        feedbackInit({
          type: 'danger',
          message:
            (e as { response: { data: { message: string } } })?.response?.data?.message || 'An error occurred while trying to fetch queries'
        });
      }
    }
  );

  return (
    <section className="processor-query">
      <div className="content-box">
        <div data-testid="processor-query-heading" className="element-box-heading settlements-settings__heading">
          <div className="heading-box-mmd">
            <h5 className="form-header">Processor Query</h5>
            <p className="form-desc no-underline">
              Here, you will be able to sync and verify transaction statuses between Kora and other payment processors.
            </p>
          </div>
        </div>

        <ProcessorQuerySelectors
          ids={ids}
          setIds={setIds}
          queryProcessorAsyncFn={mutateAsync}
          isLoading={isLoading}
          processor={processor}
          setProcessor={setProcessor}
        />
        <ProccessorQueryResultContainer
          views={views}
          setViews={setViews}
          processor={processor}
          isLoading={isLoading}
          queries={queries}
          setQueries={setQueries}
        />
      </div>
    </section>
  );
};

export default ProcessorQuery;
