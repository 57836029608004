import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import Icon from '+containers/Dashboard/Shared/Icons';
import Modal from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { ProcessorQueryType } from '+types';
import { capitalize } from '+utils';

import ProcessorQueryPagination from './ProcessorQueryPagination';
import ProcessorQueryResult from './ProcessorQueryResult';
import { perPage, processorQueryResultModalContent } from './processorQueryResultHelpers';
import ProcessorQueryResultPlaceholder from './ProcessorQueryResultPlaceholder';

import './index.scss';

const api = new APIRequest();

const ProccessorQueryResultContainer = ({
  queries = [],
  setQueries,
  isLoading,
  processor,
  views,
  setViews
}: {
  queries: ProcessorQueryType[];
  setQueries: Dispatch<SetStateAction<ProcessorQueryType[]>>;
  isLoading: boolean;
  processor: string;
  setViews: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  views: { [key: string]: boolean };
}) => {
  const { feedbackInit } = useFeedbackHandler();

  const [modal, setModal] = useState('');
  const [page, setPage] = useState(1);
  const [paginatedList, setPaginatedList] = useState<ProcessorQueryType[]>([]);
  const [idsToDeleteOrRepush, setIdsToDeleteOrRepush] = useState<string[]>([]);

  // Keep track of the ids that have been repushed
  const queriedIds = useRef<string[]>([]);
  const noValidQueryToRepush = !queries.some(q => q.cleared && !queriedIds.current.includes(q.reference));
  const isMultipleQuery = queries.length > 1;
  const viewAll = isMultipleQuery && Object.values(views).some((view, i) => i < perPage * page && i + 1 > (page - 1) * perPage && view);

  const { isLoading: loadingRepush, mutateAsync } = useMutation<ProcessorQueryType[], unknown, string[]>(
    (ids: string[]) => api.repushVbaProcessorTransactions({ references: ids, processor }),
    {
      onSuccess: (data, ids) => {
        queriedIds.current = [...queriedIds.current, ...ids];

        const updatedQueries = queries.reduce((prev, next) => {
          if (ids.includes(next.reference)) {
            const newQuery = data.find(d => d.reference === next.reference);
            if (newQuery) {
              return [...prev, newQuery];
            }
            return prev;
          }
          return [...prev, next];
        }, [] as ProcessorQueryType[]);

        setQueries(updatedQueries);
        queriedIds.current = [...queriedIds.current, ...ids];
      },
      onError: e => {
        feedbackInit({
          type: 'danger',
          message: (e as { response: { data: { message: string } } })?.response?.data?.message || 'An error occurred while trying to repush'
        });
      },
      onSettled: (_, __, ids) => {
        setIdsToDeleteOrRepush(prev => prev.filter(i => !ids.includes(i)));
      }
    }
  );

  const removeQueryFn = (arg?: string) => {
    if (!arg) {
      setQueries([]);
      setPaginatedList([]);
      setViews({});
      setIdsToDeleteOrRepush([]);
      return;
    }

    const filteredResponse = queries.filter(res => res.reference !== arg);
    const updatedPaginatedList = filteredResponse.slice((page - 1) * perPage, page * perPage);

    setPaginatedList(updatedPaginatedList);
    setQueries(filteredResponse);

    if (arg in views) {
      const { [arg]: _, ...rest } = views;
      setViews(rest);
    }
  };

  const openRemoveQueryModal = (arg?: string) => {
    if (arg) {
      setIdsToDeleteOrRepush([arg]);
      setModal('removeQuery');
    } else {
      setIdsToDeleteOrRepush([]);
      setModal('removeAllQueries');
    }
  };

  const pushQueriesFn = (arg: string[]) => {
    setIdsToDeleteOrRepush(arg);
    setModal('pushAllQueries');
  };

  const modalContent = processorQueryResultModalContent({
    modal,
    removeQueryFn,
    idsToDeleteOrRepush,
    setModal,
    repushFn: mutateAsync,
    loading: loadingRepush
  });

  const toggleAllViews = () => {
    setViews(queries.reduce((prev, next) => ({ ...prev, [next.reference]: !viewAll }), {}));
  };

  useEffect(() => {
    setPaginatedList(queries.slice((page - 1) * perPage, page * perPage));

    if (!Object.keys(views).length && queries.length) {
      setViews(
        isMultipleQuery ? queries.reduce((prev, next) => ({ ...prev, [next.reference]: viewAll }), {}) : { [queries[0]?.reference]: true }
      );
    }
  }, [page, queries.length]);

  return (
    <div data-testid="processor-query-result-container" className="processor-query-result-container">
      {(isLoading || !queries.length) && <ProcessorQueryResultPlaceholder loading={isLoading} />}
      {!isLoading && !!queries.length && (
        <div data-testid="processor-query-response">
          <div className="query-result-header">
            <div className="header-description">
              <h6>Query Response from Processor ({capitalize(processor)})</h6>
              <hr />
              <button onClick={() => openRemoveQueryModal()} className="clear-query-btn">
                Clear response{isMultipleQuery && 's'} <Icon name="circledClose" fill="#94A7B7" />
              </button>
            </div>
            <div className="push-query-wrapper">
              <button disabled={!isMultipleQuery} className="btn toggle-view" onClick={toggleAllViews}>
                {viewAll ? 'Collapse' : 'Expand'} all
              </button>
              <hr />
              <button
                disabled={!isMultipleQuery || noValidQueryToRepush}
                onClick={() => {
                  pushQueriesFn(
                    queries.filter(q => q.cleared && q.success && !queriedIds.current.includes(q.reference)).map(q => q.reference)
                  );
                }}
                className="btn push-query-btn"
              >
                Push All Responses
              </button>
            </div>
          </div>

          <div className="query-result-content">
            {paginatedList.map(query => (
              <ProcessorQueryResult
                idsToDeleteOrRepush={idsToDeleteOrRepush}
                setIdToDeleteOrRepush={setIdsToDeleteOrRepush}
                key={query.reference}
                query={query}
                views={views}
                setViews={setViews}
                isMultipleQuery={isMultipleQuery}
                openRemoveQueryModal={openRemoveQueryModal}
                repushFn={mutateAsync}
                queriedIds={queriedIds.current}
              />
            ))}
          </div>

          {isMultipleQuery && <ProcessorQueryPagination page={page} setPage={setPage} numOfQuery={queries.length} />}
        </div>
      )}

      {modalContent && (
        <Modal
          close={() => setModal('')}
          size={modalContent.size}
          heading={modalContent.heading}
          description={modalContent.description}
          content={modalContent.content}
          firstButtonText={modalContent.firstButtonText}
          secondButtonText={modalContent.secondButtonText}
          secondButtonColor={modalContent.secondButtonColor}
          modalBanner={modalContent.modalBanner}
          secondButtonAction={modalContent.secondButtonAction}
          secondButtonActionIsTerminal={false}
          showButtons={modalContent.showButtons}
        />
      )}
    </div>
  );
};

export default ProccessorQueryResultContainer;
