import React, { Dispatch, SetStateAction } from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import { ProcessorQueryType } from '+types';

export const perPage = 25;

export const processorQueryResultModalContent = ({
  idsToDeleteOrRepush,
  modal,
  removeQueryFn,
  setModal,
  repushFn,
  loading
}: {
  idsToDeleteOrRepush: string[];
  modal: string;
  removeQueryFn: (arg?: string) => void;
  setModal: Dispatch<SetStateAction<string>>;
  repushFn: (ids: string[]) => Promise<ProcessorQueryType[]>;
  loading: boolean;
}) =>
  ({
    pushAllQueries: {
      heading: 'Push All Transactions',
      modalBanner: loading && <div className="query-modal-banner">Please do not close this modal...</div>,
      description: 'Are you sure you want to push these transactions? This action can not be reversed!',
      content: (
        <div className="query-modal-content">
          <Icon className="warning-icon" name="warningOrange" width={20} height={20} />
          <p>
            <strong>Note: </strong>This action will sync the statuses of these transactions between Kora and the selected payment processor.
          </p>
        </div>
      ),
      size: 'md' as const,
      firstButtonText: 'Close',
      secondButtonText: 'Yes, Push All',
      secondButtonColor: '#24B314',
      secondButtonAction: async () => {
        await repushFn(idsToDeleteOrRepush);
        setModal('');
      }
    },
    removeQuery: {
      heading: 'Remove Query Response?',
      description: 'Are you sure you want to remove this query response? This action can not be reversed!',
      content: (
        <div className="query-modal-content">
          <Icon className="warning-icon" name="warningOrange" width={20} height={20} />
          <p>
            <strong>Note: </strong>This response will be permanently removed from the list.
          </p>
        </div>
      ),
      size: 'md' as const,
      firstButtonText: 'Go back',
      secondButtonText: 'Yes, Remove',
      secondButtonColor: '#F32345',
      secondButtonAction: () => {
        removeQueryFn(idsToDeleteOrRepush[0]);
        setModal('');
      }
    },
    removeAllQueries: {
      heading: 'Clear All Responses?',
      description: (
        <div style={{ marginTop: 15 }}>Are you sure you want to clear all query responses? This action can not be reversed!</div>
      ),
      size: 'mdBase' as const,
      firstButtonText: 'Go back',
      secondButtonText: 'Yes, Clear',
      secondButtonColor: '#F32345',
      secondButtonAction: () => {
        removeQueryFn();
        setModal('');
      }
    },

    removeQuerySuccess: {
      heading: '',
      size: 'mdBase' as const,
      content: (
        <div className="success-query-modal">
          <Icon name="circledSuccessWithLightGreenBg" width={90} height={90} />
          <h3>Query Response Removed</h3>
          <p data-testid="success-modal">You have successfully removed this query response</p>
          <button
            data-testid="dismiss-btn"
            className="success-btn"
            type="button"
            onClick={() => setModal('')}
            aria-label="close success modal"
          >
            Dismiss
          </button>
        </div>
      ),
      showButtons: false
    }
  }[modal]);

export const pushProcessorJsxFn = ({
  query,
  queriedIds,
  idsToDeleteOrRepush,
  setIdToDeleteOrRepush,
  loading,
  repushFn,
  setLoading
}: {
  query: ProcessorQueryType;
  queriedIds: string[];
  idsToDeleteOrRepush: string[];
  setIdToDeleteOrRepush: Dispatch<SetStateAction<string[]>>;
  loading: boolean;
  repushFn: (ids: string[]) => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const repushing = idsToDeleteOrRepush.includes(query.reference) && loading;
  const disableRepushBtn = !query.cleared || repushing;

  if (queriedIds.includes(query.reference)) {
    return (
      <strong className="single-query-success">
        Done <Icon name="check" stroke="#24B314" width={14} />
      </strong>
    );
  }

  return (
    <>
      <i>
        Check: <strong>{!query.cleared && 'Not'} Cleared</strong>
      </i>
      <hr />
      <span
        aria-disabled={disableRepushBtn}
        className={`push-processor-btn push-processor-btn${query.cleared ? '' : '-disabled'}`}
        role="button"
        aria-label="push-processor"
        data-testid="repush-processor-btn"
        onClick={async e => {
          e.stopPropagation();
          if (disableRepushBtn) return;

          setIdToDeleteOrRepush(prev => [...new Set([...prev, query.reference])]);
          setLoading(true);

          await repushFn([query.reference]);

          setLoading(false);
        }}
      >
        {repushing ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : 'Push Response'}
      </span>
    </>
  );
};
