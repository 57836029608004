import React from 'react';

const ProcessorQueryResultPlaceholder = ({ loading }: { loading: boolean }) => {
  return (
    <div data-testid="query-result-placeholder">
      <h6>Query Result</h6>

      <div className="query-result-placeholder-container">
        <div className="query-result-placeholder">
          {loading && (
            <span className="spinner-border spinner-border-sm" style={{ height: 30, width: 30 }} role="querying" aria-hidden="true" />
          )}
          <h6>{loading ? 'Querying processor...' : 'Query result details will show here'}</h6>
          <p>{loading ? 'Please do not close this page' : 'Simply enter the processor ID and click on query to see results.'}</p>
        </div>
      </div>
    </div>
  );
};

export default ProcessorQueryResultPlaceholder;
