import React, { Dispatch, SetStateAction } from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';

import { perPage } from './processorQueryResultHelpers';

const ProcessorQueryPagination = ({
  numOfQuery,
  page,
  setPage
}: {
  numOfQuery: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}) => {
  const lastPage = Math.ceil(numOfQuery / perPage);

  return (
    <div data-testid="query-pagination" className="query-pagination">
      <strong data-testid="number-of-query-pagination">({numOfQuery}) Query Response(s)</strong>
      <div className="query-pagination__btn-wrapper">
        <button
          data-testid="first-page-pagination-btn"
          disabled={page === 1}
          aria-label="Go to first"
          onClick={() => setPage(1)}
          className="btn"
          type="button"
        >
          <Icon name="doubleCaretLeft" stroke="currentColor" width={8} height={8} />
        </button>
        <button
          data-testid="prev-page-pagination-btn"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
          aria-label="Go to previous"
          className="btn"
          type="button"
        >
          <Icon name="caretLeft" stroke="currentColor" width={8} height={8} />
        </button>
        <button
          data-testid="next-page-pagination-btn"
          disabled={page === lastPage}
          onClick={() => setPage(page + 1)}
          aria-label="Go to next"
          className="btn"
          type="button"
        >
          <Icon name="caretRight" stroke="currentColor" width={8} height={8} />
        </button>
        <button
          data-testid="last-page-pagination-btn"
          disabled={page === lastPage}
          onClick={() => setPage(lastPage)}
          aria-label="Go to last"
          className="btn"
          type="button"
        >
          <Icon name="doubleCaretRight" stroke="currentColor" width={8} height={8} />
        </button>
      </div>
    </div>
  );
};

export default ProcessorQueryPagination;
