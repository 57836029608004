import AuditLogs from '+containers/Dashboard/AuditLogs';
import CardIssuance from '+containers/Dashboard/CardIssuance';
import CurrencyExchange from '+containers/Dashboard/CurrencyExchange';
import Disputes from '+containers/Dashboard/Disputes';
import Identity from '+containers/Dashboard/Identity';
import Merchants from '+containers/Dashboard/Merchants';
import MerchantDetails from '+containers/Dashboard/Merchants/MerchantDetails';
import MerchantKycComponent from '+containers/Dashboard/Merchants/MerchantKyc';
import PartnersBalance from '+containers/Dashboard/PartnersBalance';
import Paused from '+containers/Dashboard/PausedPayments';
import PayIn from '+containers/Dashboard/PayIn';
import PayOut from '+containers/Dashboard/PayOut';
import BulkPayoutContainer from '+containers/Dashboard/PayOut/BulkPayouts';
import ProcessorQuery from '+containers/Dashboard/ProcessorQuery';
import ProductConfig from '+containers/Dashboard/ProductConfig';
import Reports from '+containers/Dashboard/Reports';
import Reversals from '+containers/Dashboard/Reversals';
import Settings from '+containers/Dashboard/Settings';
import SettlementPayouts from '+containers/Dashboard/SettlementPayouts';
import Settlements from '+containers/Dashboard/Settlements';
import Users from '+containers/Dashboard/Users';
import UserNewInvite from '+containers/Dashboard/Users/UserNewInvite';
import Utilities from '+containers/Dashboard/Utilities';
import VirtualAccounts from '+containers/Dashboard/VirtualAccounts';
import Webhooks from '+containers/Dashboard/Webhooks';
import Welcome from '+containers/Dashboard/Welcome';
import { IMatchRoutesToComponents } from '+hooks/usePermission';

import DashboardHome from '../../Home';

export const matchDashboardComponents: IMatchRoutesToComponents = {
  welcome: ['home', 'os-icon-home', Welcome],
  dashboard: ['analytics', 'os-icon-pie-chart', DashboardHome],
  merchant: ['merchants', 'os-icon-users', Merchants],
  virtual_accounts: ['virtual-accounts', 'os-icon-hash', VirtualAccounts],
  pay_ins: ['pay-ins', 'os-icon-arrow-down-left', PayIn],
  payouts: ['payouts', 'os-icon-arrow-up-right', PayOut],
  payment_reversals: ['payment-reversals', 'os-icon-rotate-ccw', Reversals],
  settlements: ['settlements', 'os-icon-zap', Settlements],
  settlement_payouts: ['settlement-payouts', 'os-icon-navigation', SettlementPayouts],
  disputes: ['disputes', 'os-icon-corner-up-left', Disputes],
  card_issuance: ['card-issuance', 'os-icon-layers', CardIssuance],
  webhooks: ['webhooks', 'os-icon-git-pull-request', Webhooks],
  audit_logs: ['audit-logs', 'os-icon-clipboard', AuditLogs],
  settings: ['settings', 'os-icon-ui-46', Settings],
  'admin_users,admin_user_invitations': ['users', 'os-icon-user', Users],
  'merchant_general_details,merchant_balances,merchant_rolling_reserve_config,merchant_settlement_account,merchant_settlement_config,merchant_fee_config,merchant_teams,merchant_status,merchant_audit_logs':
    ['', null, MerchantDetails],
  'merchant_compliance,merchant_compliance_details': ['', null, MerchantKycComponent],
  admin_user_invitations: ['', null, UserNewInvite],
  utilities: ['utilities', 'os-icon-command', Utilities],
  currency_exchange: ['currency-exchange', 'os-icon-repeat', CurrencyExchange],
  transaction_config_list: ['product-config', 'os-icon-database', ProductConfig],
  bulk_payouts: ['bulk-payouts', 'os-icon-briefcase', BulkPayoutContainer],
  paused: ['paused', 'os-icon-pause', Paused],
  identity: ['identity', 'os-icon-fingerprint', Identity],
  third_party_reports: ['reports', 'os-icon-file', Reports],
  partner_funding: ['partners-balance', 'os-icon-grid', PartnersBalance],
  processor_query: ['processor-query', 'os-icon-cpu', ProcessorQuery]
};
