import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';

import BasicSelect from '+containers/Dashboard/Shared/BasicSelect';
import Icon from '+containers/Dashboard/Shared/Icons';
import { ProcessorQueryType } from '+types';

import { idCountLimit, idCountLimitWarning, processorList, queryPaymentType } from './processorQueryWidgetsHelpers';

const ProcessorQuerySelectors = ({
  ids,
  setIds,
  processor,
  setProcessor,
  isLoading,
  queryProcessorAsyncFn
}: {
  ids: string[];
  setIds: Dispatch<SetStateAction<string[]>>;
  processor: string;
  setProcessor: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  queryProcessorAsyncFn: () => Promise<ProcessorQueryType[]>;
}) => {
  const [paymentType, setPaymentType] = useState('Pay-ins');

  const [inputId, setInputId] = useState('');
  const [error, setError] = useState<ReactElement | string>('');

  const disableQueryBtn = !!error || !paymentType || !processor || !ids.length;

  const deleteIdFn = (id: string) => {
    setIds(prev => prev.filter(i => i !== id));
  };

  const editIdFn = (id: string) => {
    setInputId(id);
    deleteIdFn(id);
  };

  const scrollIdsWrapperToEnd = () => {
    const idsContainer = document.getElementById('inputted-ids-wrapper');

    idsContainer?.scrollTo?.({
      left: idsContainer.scrollWidth,
      behavior: 'smooth'
    });
  };

  const addId = (str: string) => {
    const val = str.trim();

    if (ids.length >= idCountLimit) {
      setError(
        <>
          You can only query up to <strong>{idCountLimit} processor IDs</strong> at once
        </>
      );
      return;
    }

    if (ids.map(id => id.toLowerCase()).includes(val.toLowerCase())) {
      setError('ID already added');
      return;
    }

    if (val) {
      setIds(prev => [...prev, val]);
      setInputId('');
      setTimeout(() => scrollIdsWrapperToEnd(), 50);
    }
  };

  const queryFn = async () => {
    const data = await queryProcessorAsyncFn();
    if (data) {
      setIds([]);
      setInputId('');
    }
  };

  useEffect(() => {
    if (error && !inputId) setError('');
    if (ids.length !== idCountLimit && !ids.map(id => id.toLowerCase()).includes(inputId.toLowerCase())) setError('');
  }, [ids.length, inputId]);

  return (
    <div className="query-container" data-testid="processor-query-selectors">
      <div className="query-selector-wrapper">
        <BasicSelect
          className="select-query-type"
          dataTestId="select-query-type"
          placeholder={queryPaymentType[0].label}
          setValue={setPaymentType}
          options={queryPaymentType}
        />

        <BasicSelect
          className="select-query-processor"
          dataTestId="select-query-processor"
          placeholder="Select Processor"
          setValue={setProcessor}
          options={processorList}
        />
      </div>

      <div className="query-processor-input-submit-wrapper">
        <div className="id-input-container">
          <div className="query-processor-id-input-wrapper">
            <div
              className={`${ids ? 'input-count' : 'input-count-inactive'} ${ids.length >= idCountLimitWarning ? 'input-count-limit' : ''}`}
            >
              {ids.length}/{idCountLimit}
            </div>

            <div className="inputted-ids-wrapper" id="inputted-ids-wrapper">
              {ids.map(id => {
                return (
                  <div key={id} className="inputted-id" data-testid="inputted-id">
                    <button onClick={() => editIdFn(id)} className="id">
                      {id}
                    </button>
                    <hr />
                    <button onClick={() => deleteIdFn(id)} className="btn delete-id">
                      <Icon name="cancel" stroke="currentColor" width={16} height={16} />
                    </button>
                  </div>
                );
              })}
            </div>

            <input
              data-testid="input-processor-ids"
              aria-label="input-processor-ids"
              placeholder="Enter Processor ID"
              value={inputId}
              maxLength={50}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  addId(inputId);
                  return;
                }

                if (e.key === 'Backspace' && !inputId) {
                  setIds(prev => prev.slice(0, -1));
                }
              }}
              onChange={e => {
                const value = e.target.value;

                if (value.endsWith(' ')) addId(value);
                else setInputId(value);
              }}
            />

            <button
              disabled={!ids.length}
              onClick={() => {
                setIds([]);
                setInputId('');
              }}
              className="clear-processor-id"
              aria-label="clear processor"
            >
              <Icon name="close" width={16} height={16} />
            </button>
          </div>

          {error && (
            <div className="error-msg">
              <Icon name="warningOrange" fill="red" />
              {error}
            </div>
          )}
        </div>

        <button onClick={queryFn} disabled={disableQueryBtn || isLoading} data-testid="processor-query-btn" className="btn query-btn">
          {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : 'Query'}
        </button>
      </div>
    </div>
  );
};

export default ProcessorQuerySelectors;
