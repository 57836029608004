import React, { Dispatch, SetStateAction, useState } from 'react';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import Icon from '+containers/Dashboard/Shared/Icons';
import RenderStyledJson, { IData } from '+containers/Dashboard/Shared/RenderStyledJson';
import { ProcessorQueryType } from '+types';
import { capitalize } from '+utils';

import { pushProcessorJsxFn } from './processorQueryResultHelpers';

const ProcessorQueryResult = ({
  query,
  views,
  setViews,
  isMultipleQuery,
  openRemoveQueryModal,
  repushFn,
  idsToDeleteOrRepush,
  setIdToDeleteOrRepush,
  queriedIds
}: {
  query: ProcessorQueryType;
  views: { [key: string]: boolean };
  setViews: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  isMultipleQuery: boolean;
  idsToDeleteOrRepush: string[];
  setIdToDeleteOrRepush: Dispatch<SetStateAction<string[]>>;
  openRemoveQueryModal: (arg: string) => void;
  queriedIds: string[];
  repushFn: (ids: string[]) => void;
}) => {
  const showResponse = views[query.reference];
  const [loading, setLoading] = useState(false);

  const viewFn = (arg: string) => {
    if (!isMultipleQuery) return;

    setViews(prev => ({ ...prev, [arg]: !prev[arg] }));
  };

  return (
    <div className="query-response">
      <div
        role="button"
        tabIndex={0}
        aria-expanded={showResponse}
        onClick={() => viewFn(query.reference)}
        className={`processor-heading processor-heading${query.success ? '' : '-error'}`}
        style={{ borderRadius: showResponse ? '' : 8, cursor: isMultipleQuery ? 'pointer' : 'default' }}
      >
        <div className="processor-id">
          <span>
            Processor ID: <strong>{capitalize(query.reference)}</strong>
          </span>
          <Icon className="view-toggle-icon" name="caretDownSolid" style={{ transform: views[query.reference] ? 'rotateZ(180deg)' : '' }} />
          {!query.success && (
            <div className="id-not-found">
              <Icon name="warningTriangle" width={16} height={16} />
              <span>Unsuccessful</span>
            </div>
          )}
        </div>
        <div className="push-processor">
          {query.success && (
            <>
              {pushProcessorJsxFn({
                query,
                queriedIds: queriedIds,
                idsToDeleteOrRepush,
                repushFn,
                setIdToDeleteOrRepush,
                loading,
                setLoading
              })}
              <hr />
              <span
                role="button"
                onClick={e => {
                  e.stopPropagation();
                  openRemoveQueryModal(query.reference);
                }}
                aria-label="remove-single-processor"
                data-testid="remove-single-processor"
              >
                <Icon name="close" width={18} height={18} />
              </span>
            </>
          )}
        </div>
      </div>
      {(showResponse || !isMultipleQuery) && (
        <div className="animated-response">
          <div className="processor-response">
            <RenderStyledJson data={query.response as IData} showAllOnRender allowCopy={false} />
          </div>
          <div className="processor-response-footer">
            <div className="copy-response">
              <Copyable text="Copy Code" textToCopy={JSON.stringify(query.response)} textBeforeIcon={false} />
              <hr /> <span>JSON</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProcessorQueryResult;
