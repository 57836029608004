import React from 'react';

import Copyable from '../Copyable';
import Icon from '../Icons';

import './index.scss';

export interface IData {
  [key: string]: string | number | undefined | null | IData;
}

export default function RenderStyledJson({
  data,
  showAllOnRender = false,
  allowCopy = true
}: {
  data: IData;
  showAllOnRender?: boolean;
  allowCopy?: boolean;
}) {
  const [showMore, setShowMore] = React.useState(showAllOnRender);
  return (
    <div className="render-styled-json">
      {allowCopy && (
        <div className="render-styled-json__copy">
          <Copyable textToCopy={JSON.stringify(data)} text="" />
        </div>
      )}
      <div className={`code-block ${showMore ? 'show-more' : ''}`}>{renderObject(data)}</div>
      {!showMore && (
        <button
          data-testid="show-btn"
          type="button"
          className="btn btn-link render-styled-json__btn"
          onClick={() => setShowMore(prev => !prev)}
        >
          Show more <Icon name="caretDown" />
        </button>
      )}
    </div>
  );
}

const renderObject = (data: IData) => {
  const isArray = Array.isArray(data);
  return (
    <pre>
      <span className="curly-brace">{isArray ? '[' : '{'}</span>
      {typeof data === 'string' ? (
        <span className="value">{JSON.stringify(data)}</span>
      ) : (
        Object.entries(data).map(([key, value]) => {
          const tempValue = value ?? 'null';
          return (
            <div key={key}>
              {!isArray && <span className="key">{JSON.stringify(key)}:</span>}
              {typeof tempValue === 'object' ? (
                renderObject(tempValue)
              ) : (
                <span className={`value ${typeof value === 'number' && 'number'}`}>{JSON.stringify(tempValue)}</span>
              )}
            </div>
          );
        })
      )}
      <span className="curly-brace">{isArray ? ']' : '}'}</span>
    </pre>
  );
};
