import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { DisputeRefundStatusType, IRefundDetails } from '+types';
import { history, isAllowed, switchTrxnMessage } from '+utils';

import ManageRefundsModal from './ManageRefundsModal';
import { generateHeaderSummary, generatePayerDetailsSummary, generateRefundDetailsSummary, getActionButtons } from './RefundsDetailsHelper';

const api = new APIRequest();
export default function RefundsDetails() {
  const { id } = useParams<{ id: string }>();
  const userAccess = useSetUserAccess() as { [key: string]: boolean };
  const [manageRefundsModalOpen, setManageRefundsModalOpen] = useState<boolean>(false);

  const { feedbackInit } = useFeedbackHandler();

  const { data, refetch, isLoading } = useQuery<IRefundDetails>([`TRANSACTION_DETAILS_${id}`], () => api.getSingleRefund(id), {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
        type: 'danger',
        action: { name: 'retry', action: () => refetch() }
      });
      history.goBack();
    },
    enabled: !!isAllowed(userAccess, ['refund_details.view'])
  });

  const summaries = generateHeaderSummary(data as IRefundDetails);
  const actionButtons = getActionButtons({
    status: data?.status as DisputeRefundStatusType,
    permissions: userAccess ?? {},
    setManageRefundsModalOpen
  });
  const refundDetails = generateRefundDetailsSummary(data as IRefundDetails);
  const payerDetails = generatePayerDetailsSummary(data as IRefundDetails);

  return (
    <>
      <TransactionDetails>
        <TransactionDetails.Header
          heading={data?.merchant_reference || id?.toUpperCase?.()}
          status={String(
            switchTrxnMessage[data?.status as unknown as keyof typeof switchTrxnMessage]?.name || switchTrxnMessage?.processing?.name
          )}
          statusBg={String(switchTrxnMessage[data?.status as keyof typeof switchTrxnMessage]?.backgroundColor)}
          statusColor={String(switchTrxnMessage[data?.status as keyof typeof switchTrxnMessage]?.color)}
          isLoading={isLoading}
          summaries={summaries}
          actionButtons={actionButtons}
        />
        <TransactionDetails.Section isLoading={isLoading} heading="Refund Details" summaries={refundDetails} />
        <TransactionDetails.Section isLoading={isLoading} heading="Payer's Information" summaries={payerDetails} />
      </TransactionDetails>
      {manageRefundsModalOpen && (
        <ManageRefundsModal
          close={() => setManageRefundsModalOpen(false)}
          visible={manageRefundsModalOpen}
          referenceId={id}
          currentStatus={data?.status as DisputeRefundStatusType}
        />
      )}
    </>
  );
}
