import React from 'react';

import RenderStyledJson from '+containers/Dashboard/Shared/RenderStyledJson';

import './index.scss';

export type TEventData = Record<string, string>;

export default function EventData({ data }: { data: TEventData }) {
  return (
    <div className="ied">
      <h3 className="ied__header">Event Data</h3>
      <div className="ied__content">
        {data && (
          <div className="json-wrapper">
            <RenderStyledJson data={data} />
          </div>
        )}
      </div>
    </div>
  );
}
